import { render, staticRenderFns } from "./institution.vue?vue&type=template&id=1bbc3710&scoped=true&"
import script from "./institution.vue?vue&type=script&lang=js&"
export * from "./institution.vue?vue&type=script&lang=js&"
import style0 from "./institution.vue?vue&type=style&index=0&id=1bbc3710&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbc3710",
  null
  
)

export default component.exports